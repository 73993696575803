<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadcrumb" />     
        <div class="section course-page">
            <div class="container">
                <div class="row">
                    <div class="layout_padding_2" :class="(pageData.image_name) ? 'col-md-6' : 'col-md-12'">
                        <div class="full">
                            <div class="heading_main text_align_left">
                                <h2>{{ pageData.page_heading }}</h2>
                            </div>
                            <div class="full" v-html="pageData.content"></div>
                        </div>
                    </div>
                    <div class="col-md-6" v-if="pageData.image_name">
                        <div class="full"> 
                            <img
                                :alt="pageData.page_heading"
                                data-sizes="auto"
                                :data-src="dir_path.thumb"
                                :data-srcset="dir_path.thumb+' '+'100w, '+
                                dir_path.thumb+' '+'600w, '+
                                dir_path.thumb+' '+'900w'" class="lazyload" 
                            />
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </section>
</template>

<script>
import InsideHeader from "../components/InsideHeader.vue";
import Translation from '../../public/translation.json'

export default {
    components: {
        InsideHeader
    },
    data(){
        return {
            breadcrumb: [],
            dir_path: '',
            pageData: {},
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = this.$route.params.slug
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    this.breadcrumb = [
                        {title: this.pageContent('home'), path:'/'},
                        {title: this.pageData.page_heading, path: '#'}
                    ]
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.$router.push({name: '404'})
            })
        }
    },
    created: function(){
        this.getPageData()
    },
    watch: {
        "$route.params.slug": function(){
            this.getPageData()
        }
    }
}
</script>